<template>
  <div class="policy">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>政策文库</el-breadcrumb-item>
    </el-breadcrumb>
    <div >
      <!-- <policySearch @search-data="handleSearch" @getFocus="getFocus" /> -->
      <keep-alive>
        <newSearch></newSearch>
      </keep-alive>
      <keep-alive>
        <zhengceChoose v-model="zhengceChoose" @change="handleChoose" />
      </keep-alive>
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>

<script>
// import policySearch from './components/policySearch'
import zhengceChoose from "./components/zhengceChoose";
import newSearch from "./components/search.vue";
export default {
  name: "PolicyLibrary",
  components: {
    // policySearch,
    zhengceChoose,
    newSearch,
  },
  data() {
    return {
      search: "",
      zhengceChoose: "/policy/tongzhi",
    };
  },
  mounted() {
    this.handleRoute();
  },
  methods: {
    handleSearch(data) {
      this.search = data;
    },
    handleRoute() {
      let path = this.$route.path;
      if (path.endsWith("/")) path = path.slice(0, -1);
      this.zhengceChoose = path;
    },
    handleClick(tab, event) {},
    handleChoose(data) {
      this.$router.push({ path: data });
    },
    getFocus(data) {},
  },
  watch: {
    $route: "handleRoute",
  },
};
</script>

<style scoped>
.policy {
  background-color: #fff;
  padding: 43px 0px 0 0px;
}
</style>
