<template>
    <div class="search">
        <div>
            <h3>政策文库</h3>
            <div style="color:#000000">中央到镇街五级政府政策，一键查询</div>
        </div>
        <div>
            <el-autocomplete
              popper-class="my-autocomplete"
              v-model="searchText"
              :fetch-suggestions="querySearch"
              placeholder="请输入政策名称/项目名称"
              style="min-width:666px;"
              clearable
              ref="cautocomplete"
              @clear="clear"
              :debounce="300"
              :popper-append-to-body="false"
              @keyup.enter.native="keyupDown"
              @select="handleSelect"
            >
              <template slot-scope="{ item }">
                <div style="display:flex">
                  <div
                    v-if="searchText"
                    class="keyRecord"
                    @click.stop="handleClick(item)"
                    style="white-space:nowrap;text-overflow: ellipsis;overflow: hidden;flex:1;width:300px"
                  >{{ item.keyRecord }}</div>
                  <div
                    class="keyRecord"
                    style="display: flex;"
                    v-if="!searchText && !item.isHis"
                    @mouseenter="item.active = true"
                    @mouseleave="item.active = false"
                  >
                      <div
                        style="white-space:nowrap;text-overflow: ellipsis;overflow: hidden;flex:1;width:550px"
                        @click.stop="handleHistory(item)"
                      >{{ item.keyword }}</div>
                      <div
                        v-if="item.active"
                        @click.stop="delHistory(item, $event)"
                        class="keyRecord-icon"
                      ><i class="el-icon-close"></i></div>
                  </div>
                  <div
                    v-if="!searchText && item.isHis"
                    style="width:100%;text-align:center;color:#156ED0"
                    @click.stop="handleDelAll"
                  >{{item.keyword}}
                  </div>
                  <div
                    v-if="searchResult.length > 0 && !item.isHis"
                    class="policy"
                    style="margin-left:20px"
                    @click.stop="handleNotice(item)"
                  >
                      <!-- 申报通知 -->
                      申报通知({{ item.noticeCount }})
                  </div>
                  <div
                    v-if="searchResult.length > 0 && !item.isHis"
                    class="notice"
                    style="margin-left:20px"
                    @click.stop="handlePolicy(item)"
                  >
                      <!-- 政策文件 -->
                      政策文件({{ item.policyCount }})
                  </div>
                </div>
              </template>
              <el-button slot="append" @click="btnSearch">搜索</el-button>
            </el-autocomplete>
        </div>
    </div>
</template>

<script>
import { request } from '@/network';
import { mapMutations } from 'vuex';
import {
  // setInfoData,
  getMemberId
  // getEntityId,
} from '@/assets/public/utils/token';
export default {
  data () {
    return {
      searchText: '',
      type: '',
      historyList: [],
      searchResult: []
    };
  },
  mounted () {
    if (this.$route.path === '/policy/tongzhi') {
      this.type = 'notice';
    } else {
      this.type = 'reg';
    }
  },
  watch: {
    '$route.path' (now, old) {
      if (now === '/policy/tongzhi') {
        this.type = 'notice';
      } else {
        this.type = 'reg';
      }
    }
  },
  methods: {
    ...mapMutations('policy', [ 'setSearch', 'clickFagui', 'clickTongzhi' ]),
    querySearch (queryString, cb) {
      const memberId = getMemberId();
      if (queryString) {
        request({
          method: 'POST',
          url: '/pcp/librarysearch/getSearchHistory',
          data: {
            keyWord: queryString,
            city: localStorage.getItem('selectCity'),
            memberId: getMemberId()
          }
        }).then(res => {
          if (res.data.code !== 200) return this.$message.error('暂无数据');
          const result = res.data.data;
          this.searchResult = res.data.data;
          cb(result);
        });
      } else {
        if (memberId) {
          request({
            method: 'GET',
            url: '/pcp/librarysearch/getMemberSearchHistory',
            params: {
              memberId: getMemberId()
            }
          }).then(response => {
            if (response.data.code !== 200) return this.$message.error('暂无数据');
            let result = [];
            const arr = [ { keyword: '清除全部历史', isHis: true } ];
            response.data.data.forEach(item => {
              item.active = false;
            });
            result = response.data.data.length > 0 ? response.data.data.concat(arr) : response.data.data;
            this.historyList = response.data.data;
            cb(result);
          });
        } else {
          const result = [];
          cb(result);
        }
      }
    },
    // 点击历史记录
    handleHistory (item) {
      this.getSearchCount(item.keyword, this.type, false);
    },
    handleNotice (item) {
      this.getSearchCount(item.keyRecord, 'notice', true);
      // this.$router.push({ path: '/policySearchNotice', query: { keyWord: item.keyRecord } })
    },
    handleClick (item) {
      this.getSearchCount(item.keyRecord, this.type, false);
    },
    handlePolicy (item) {
      this.getSearchCount(item.keyRecord, 'reg', true);
      // this.$router.push({ path: '/policySearchReg', query: { keyWord: item.keyRecord } })
    },
    btnSearch () {
      this.getSearchCount(this.searchText, this.type, false);
      // this.$router.push({ path: '/policySearchNotice', query: { keyWord: this.searchText } })
    },
    keyupDown (item) {
      // this.getSearchCount(this.searchText, 'notice', false);
    },
    handleSelect (item) {
      if (item.isHis) {
        this.handleDelAll();
      } else {
        this.searchText = item.keyword || item.keyRecord;
        this.getSearchCount(this.searchText, this.type, false);
      }
    },
    // 删除历史记录
    delHistory (item, event) {
      event = event || window.event;
      event.cancelBubble = true;
      const dom = this.$refs.cautocomplete;
      const arr = [];
      arr.push(item.id);
      request({
        method: 'POST',
        url: '/pcp/librarysearch/deleteMemberSearchHistory',
        data: {
          ids: arr
        }
      }).then(res => {
        if (res.data.code !== 200) return this.$message.error('删除失败');
        this.$message.success('删除成功');
        dom.focus();
      });
    },
    // 删除全部历史记录
    handleDelAll (event) {
      event = event || window.event;
      event.cancelBubble = true;
      const dom = this.$refs.cautocomplete;
      const arr = [];
      this.historyList.forEach(v => {
        arr.push(v.id);
      });
      request({
        method: 'POST',
        url: '/pcp/librarysearch/deleteMemberSearchHistory',
        data: {
          ids: arr
        }
      }).then(res => {
        if (res.data.code !== 200) return this.$message.error('删除失败');
        this.$message.success('删除成功');
        dom.focus();
      });
    },
    clear () {
      this.$refs.cautocomplete.activated = true;
    },
    goChildPage (value, notice, policy, type) {
      this.$router.push(
        {
          path: type === 'notice' ? '/policySearchNotice' : '/policySearchReg',
          query: {
            keyWord: value,
            notice: notice,
            policy: policy,
            type: 1,
            previous: true
          }
        });
    },
    async getSearchCount (value, type, unNormal) {
      const { data: res } = await request({
        method: 'POST',
        url: '/pcp/librarysearch/getSearchCount',
        data: {
          city: localStorage.getItem('selectCity'),
          keyWord: value,
          uuidList: '',
          isEnd: ''
        }
      });
      if (res.code !== 200) return this.$message.error('数据出错，请刷新');
      const { notice, policy } = res.data;
      this.totalNotice = notice;
      this.totalReg = policy;
      this.totalRegCopy = policy;
      if (unNormal) {
        this.goChildPage(value, notice, policy, type);
      } else {
        if ((type === 'notice' && this.totalNotice !== 0) ||
        (type === 'notice' && (this.totalNotice === 0 && this.totalReg === 0)) ||
        (type === 'reg' && (this.totalReg === 0 && this.totalNotice !== 0))) {
          this.goChildPage(value, notice, policy, 'notice');
        } else {
          this.goChildPage(value, notice, policy, 'reg');
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.search {
    display: flex;
    margin-top: 30px;
    width: 1200px;
    height: 160px;
    background: url('~@/assets/pc/images/cidian/searchBG.png') no-repeat;
    background-size: 100% 100%;
    padding: 45px 60px 35px 45px;
    box-sizing: border-box;

    >div:nth-child(1) {
        flex: 1;

        h3 {
            width: 130px;
            font-size: 30px;
            font-weight: 400;
        }

        div {
            margin-top: 17px;
            font-size: 14px;
            // color: #7c8289;
        }
    }
}
.keyRecord {
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
    width: 100%;
    padding: 0 20px;
}

.keyRecord:hover {
    color: #156ED0
}

.policy:hover {
    color: #156ED0
}

.notice:hover {
    color: #156ED0
}
.notice {
  margin-right: 20px;
}

/deep/.el-input__inner {
    height: 52px;
    line-height: 52px;
}

/deep/.el-input-group__append {
    background-color: #156ED0;
    color: #fff;
    border: 1px solid #156ED0
}
/deep/.el-autocomplete-suggestion li {
  padding: 0 !important;
  // color: red;
}
</style>
